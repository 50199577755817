import { DebugModeService } from 'src/app/services/general/debug-mode.service';
import { ModalController } from '@ionic/angular';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription, distinctUntilChanged, map } from 'rxjs';

import { UserService } from 'src/app/services/user/user.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { AccountCoupleService } from 'src/app/services/account/account-couple.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { ActionService } from 'src/app/services/account/privilege/action.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { AccountUser } from 'src/app/interfaces/account';
import { LoginComponent } from 'src/app/components/login/login/login.component';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { OnlineDbService } from '../user/online-db.service';


/**
 * Account user service
 */
@Injectable({
  providedIn: 'root'
})
export class AccountUserService implements OnInit, OnDestroy {

  /**
   * Account User
   */
  accountUserList: AccountUser[];
  /**
   * Observable Account User
   */
  observableAccountUser: any;

  ready: boolean;
  /**
   * Account ID
   */
  private accountId: string;
  /**
   * Current User
   */
  private currentUser: AccountUser;
  /**
   * Account User Suscriber
   */
  private accountUserSubscription: Subscription;

  /**
   * Constructor
   * @param router Router
   * @param modalController Modal Controller
   * @param translate Translate
   * @param afs Angular Firestore
   * @param db Angular Realtime Database
   * @param accountCoupleService Couple Service
   * @param privilegeService Privilege Service
   * @param actionService Action Service
   * @param userService User Service
   * @param errorService Error Service
   */
  constructor(
    private router: Router,
    private translate: TranslateService,
    private modalController: ModalController,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private accountCoupleService: AccountCoupleService,
    private privilegeService: PrivilegeService,
    private actionService: ActionService,
    private userService: UserService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
    private onlineDbService: OnlineDbService,
    private errorService: ErrorService,
    private functionService: FunctionService,
    private debugModeService: DebugModeService,
  ) {
    this.observableAccountUser = new BehaviorSubject<AccountUser[]>(this.accountUserList);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchAccountUser();

    this.ready = false;
    this.currentUser = null;
    this.accountUserList = null;
  }

  /**
   * Setup account user
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
   
    if (accountId) {
      // await this.privilegeService.initialize(this.accountId);
      await this.watchAccountUser();
    } else {
      this.unwatchAccountUser();

      this.ready = false;
      this.currentUser = null;
      this.accountUserList = null;
    }
  }

  /**
   * Watch account user
   */
  async watchAccountUser() {
    if (this.accountId && !this.accountUserSubscription) {
      this.accountUserSubscription = this.afs.collection(`accounts/${ this.accountId }/accountUser/`)
      .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
        const data: AccountUser = a.payload.doc.data() as AccountUser;
        if (!data.uid) { data.uid = a.payload.doc.id; }
        return data;
      }).filter((accountUser: AccountUser) => {
        return accountUser?.enable;
      }))).subscribe({
        next: (accountUserList: AccountUser[]) => {
          this.setupAccountUserList(accountUserList);
          this.ready = true;
        }, error: (err: any) => {
          this.errorService.logError(err);
          this.ready = true;
        }
      });
    }
  }

  /**
   * Unwatch account user
   */
  async unwatchAccountUser() {
    if (this.accountUserSubscription) {
      this.accountUserSubscription.unsubscribe();
      this.accountUserSubscription = null;
    }
  }

  /**
   * Setup account user list
   * @param accountUserList account user list
   */
  setupAccountUserList(accountUserList: AccountUser[]) {
    if (!this.functionService.isEqual(this.accountUserList, accountUserList)) {
      this.accountUserList = accountUserList;
      this.observableAccountUser.next(this.accountUserList);
      this.accountCoupleService.setupCoupleList(this.accountUserList);
      this.setupCurrentUserPrivilege();
    }
  }

  /**
   * Setup current user privilege
   */
  setupCurrentUserPrivilege() {
    if (this.userService.uid) {
      if (this.accountUserList.length) {
        const currentUserIndex = this.accountUserList?.findIndex(
          (x: AccountUser) => x?.uid === this.userService?.uid && x?.enable === true);

        if (this.debugModeService?.debugMode) {
          this.privilegeService.setupCurrentUserPrivilege(this.actionService.stdActionList);
        } else 
        if (currentUserIndex !== -1) {
          const currentUser: AccountUser = this.accountUserList[currentUserIndex];
          if (!this.currentUser || !this.functionService.isEqual(this.currentUser, currentUser)) {
            this.currentUser = currentUser;
            this.checkAnonymous();
            if (currentUser.owner || currentUser?.role?.coupleId) {
              this.privilegeService.setupCurrentUserPrivilege(this.actionService.actionList);
            } else if (currentUser.privilege && !this.functionService.isEmpty(currentUser.privilege)) {
              this.privilegeService.setupCurrentUserPrivilege(currentUser.privilege);
            } else {
              this.privilegeService.setupCurrentUserPrivilege(this.privilegeService.getRolePrivilege(currentUser.role.type));
            }
          }
        } else {
          this.invalidUserPrompt();
        }
      }
    }
  }

  checkAnonymous() {
    if (this.userService.user?.uid && this.userService.user?.name && this.userService.user?.isAnonymous
    && (this.currentUser?.owner || this.currentUser?.role?.coupleId)) {
      this.presentLoginModal(true, false, true);
    }
  }

  checkOwner() {
    return this.currentUser?.owner;
  }

  /**
   * User invalid
   */
  async invalidUserPrompt() {
    const modal = await this.popupService.presentAlert(this.translate.instant('ACCOUNTS.msg.invalid_login'));
    modal.onWillDismiss().then(() => {
      this.goAccountLogoutPage();
    });
  }

  /**
   * Present login modal
   * @param loginRequired login required
   * @param showAnonymousLogin show anonymous login
   * @param linkProvider link provider
   * @returns Login modal
   */
  async presentLoginModal(loginRequired?: boolean, showAnonymousLogin?: boolean, linkProvider?: boolean): Promise<any> {
    await this.popupService.dismissLoading();
    const modal = await this.modalController.create({
      component: LoginComponent,
      cssClass: 'modal-transparent',
      componentProps: {
        loginRequired,
        showAnonymousLogin,
        linkProvider
      }
    });
    modal.present();
  }

  /**
   * Get current user
   * @returns account user
   */
  getCurrenctUser(): AccountUser {
    return this.getUserByUid(this.userService.uid);
  }

  /**
   * Get user by UID
   * @param uid UID
   */
  getUserByUid(uid: string): AccountUser {
    if (uid && this.accountUserList?.length) {
      const index = this.accountUserList?.findIndex((x: any) => x?.uid === uid && x?.enable === true);
      if (index !== -1) {
        return this.accountUserList?.[index];
      }
    }
    return null;
  }

  getUserNameByUid(uid: string): string {
    if (uid === 'rsvp') {
      return this.translate.instant('MODULE.list.rsvp');
    } else {
      return this.getUserByUid(uid)?.name;
    }
  }

  /**
   * Update account user
   * @param uid UID
   * @param data Data
   */
  async updateAccountUser(uid: string, data: any) {
    if (uid && data && this.accountUserList?.findIndex((x: AccountUser) => x?.uid === uid) !== -1) {
      data.updateBy = this.updateByService.updateBy;

      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountUser/${ uid }/`);
      accountsRef.set(data, { merge: true }).then(() => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }

  /**
   * Get Presence
   * @param uid UID
   * @returns Return observable
   */
  getPresence(uid: string): Observable<any> {
    return this.onlineDbService.getUserOnlineObj(uid).valueChanges();
  }

  goAccountLogoutPage() {
    this.router.navigate(['/accounts/logout']);
  }

  async transferOwnerCall(accountId: string, ownerUid: string, newOwnerUid: string) {
    if (accountId && ownerUid && newOwnerUid) {
      await this.popupService.presentLoading();
      await this.fns.httpsCallable('transferOwnerCall')({
        accountId,
        ownerUid,
        newOwnerUid
      }).toPromise().then(async (result: any) => {
        if (result?.success) {
          this.popupService.saveSuccessToast();
        } else {
          if (result.err === 'anonymous') {
            this.popupService.presentAlert('ACCOUNT.msg.transfer_owner_anonymous');
          } else {
            this.popupService.presentActionError();
          }
        }
      }).catch((err: any) => {
        this.errorService.logError(err);
        this.popupService.presentActionError();
      });
      this.popupService.dismissLoading();
    }
  }

}
