import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, distinctUntilChanged, map } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { Seating } from 'src/app/interfaces/seating';
import { Guest } from 'src/app/interfaces/guest';
import { ModuleType } from 'src/app/types/general';
import { DynamicUrl } from 'src/app/commons/url';
import { SettingField } from 'src/app/interfaces/database';
import { GuestFixService } from './guest-fix.service';

import { PopupService } from '../general/popup.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Guest service to initial guest list module
 */
@Injectable({
  providedIn: 'root'
})
export class GuestService implements OnInit, OnDestroy {

  /**
   * Show not attending guest
   */
  notAttendingGuest: boolean;
  /**
   * Show pending guest
   */
  pendingGuest: boolean;
  /**
   * Guest list
   */
  guestList: Guest[];
  checkinGuestList: Guest[];
  trashGuestList: Guest[];
  /**
   * Guest list observable
   */
  observableGuestList: any;
  observableCheckinGuestList: any;
  observableTrashGuestList: any;
  /**
   * AccountModule
   */
  private module: ModuleType;
  /**
   * Account ID
   */
  private accountId: string;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  private trashGuestListSubscription: Subscription;
  /**
   * Deleted guest list subscription
   */
  private moduleSubscription: Subscription;
  /**
   * Guest list subscription
   */
  private giftListSubscription: Subscription;

  ready: boolean;

  /**
   * Constructor
   * @param afs angular firestore
   * @param moduleService module service
   * @param errorService error service
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private moduleService: ModuleService,
    private guestFixService: GuestFixService,
    private giftService: GiftService,
    // private dateTimeService: DateTimeService,
    private errorService: ErrorService,
    private functionService: FunctionService,
  ) {
    this.guestList = [];
    this.checkinGuestList = [];
    this.trashGuestList = [];
    
    this.observableGuestList = new BehaviorSubject<Guest[]>(this.guestList);
    this.observableCheckinGuestList = new BehaviorSubject<Guest[]>(this.checkinGuestList);
    this.observableTrashGuestList = new BehaviorSubject<Guest[]>(this.trashGuestList);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unwatchCurrentModule();
    this.unwatchGuestList();
    this.unwatchTrashGuestList();
    this.unwatchGiftList();

    this.guestList = [];
    this.checkinGuestList = [];
    this.trashGuestList = [];
  }

  /**
   * Setup Account ID and start guest related services
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;

    if (this.accountId) {
      await this.watchGuestList();
      await this.watchCurrentModule();
    } else {
      this.ready = false;
      await this.unwatchCurrentModule();
      await this.unwatchGuestList();
      await this.unwatchTrashGuestList();
      await this.unwatchGiftList();
     
      this.guestList = [];
      this.checkinGuestList = [];
      this.trashGuestList = [];
    }
  }

  /**
   * Watch current module
   */
  async watchCurrentModule() {
    if (!this.moduleSubscription) {
      this.moduleSubscription = this.moduleService.observableCurrentModule.subscribe((module: ModuleType) => {
        this.module = module;
        if (this.module === 'gift') {
          this.watchGiftList();
        } else {
          this.unwatchGiftList();
        }
        
        if (this.module === 'trash') {
          this.watchTrashGuestList();
        } else {
          this.unwatchTrashGuestList();
        }
  
        // if (this.module === 'account' || this.module === 'checkin' || this.module === 'gift' || this.module === 'guest' || this.module === 'blast') {
          
        // } else {
        //   // this.unwatchGuestList();
        // }
      });
    }
  }

  /**
   * Unwatch current module
   */
  async unwatchCurrentModule() {
    if (this.moduleSubscription) {
      this.moduleSubscription.unsubscribe();
      this.moduleSubscription = null;
    }
  }

  /**
   * Watch guest list from afs.
   * Use where condition according to module.
   */
  async watchGuestList() {
    if (this.accountId && !this.guestListSubscription) {
      if (false) {
        this.guestListSubscription = this.afs.collection(`accounts/${ this.accountId }/guests/`, 
        ref => ref.where('status.deleted', '==', false))
        .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
          let guest: Guest = a.payload.doc.data() as Guest;
          if (!guest?.guestId || guest.guestId !== a.payload.doc.id) { guest.guestId = a.payload.doc.id; }
          guest = this.setupGuestSession(guest);
          guest = this.setupGuestMobile(guest);
          
          return guest;
        }).filter((guest: Guest) => {
          return !guest?.status?.deleted;
        }))).subscribe({
          next: (guestList: Guest[]) => {
            this.setupGuestList(guestList);
            setTimeout(() => {
              // this.guestFixService.checkSettingField(guestList);
              // this.guestFixService.fixMobile(guestList);
            }, 1000);
          }, error: (err: any) => {
            console.error(err);
            this.errorService.logError(err);
          }
        });

        // if (this.restartCount < 2) {
        //   await this.functionService.delay(1500);
        //   if (this.toast && !this.guestList?.length) {
        //     await this.unwatchGuestList();
        //     this.restartCount += 1;
        //     await this.functionService.delay(500);
        //     await this.watchGuestList();
        //   }
        // }
      } else {
        this.guestListSubscription = this.afs.collection(`accounts/${ this.accountId }/guests/`, 
        ref => ref.where('status.deleted', '==', false))
        .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
          let guest: Guest = a.payload.doc.data() as Guest;
          if (!guest?.guestId || guest.guestId !== a.payload.doc.id) { guest.guestId = a.payload.doc.id; }
          guest = this.setupGuestSession(guest);
          guest = this.setupGuestMobile(guest);
          
          return guest;
        }).filter((guest: Guest) => {
          return !guest?.status?.deleted;
        }))).subscribe({
          next: async (guestList: Guest[]) => {
            this.setupGuestList(guestList);
            await this.functionService.delay(200);
            // this.guestFixService.checkSettingField(guestList);
            // this.guestFixService.fixMobile(guestList);
            this.ready = true;
          }, error: (err: any) => {
            this.errorService.logError(err);
            this.ready = true;
          }
        });
      }
      
    }
  }

  async watchTrashGuestList() {
    if (this.accountId && this.module === 'trash' && !this.trashGuestListSubscription) {
      this.trashGuestListSubscription = this.afs.collection(`accounts/${ this.accountId }/guests/`,
      ref => ref.where('status.deleted', '==', true))
      .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
        let guest: Guest = a.payload.doc.data() as Guest;
        if (!guest?.guestId) { guest.guestId = a.payload.doc.id; }
        guest = this.setupGuestSession(guest);
        return guest;
      }).filter((guest: Guest) => {
        return guest?.status?.deleted;
      }))).subscribe({
        next: (guestList: Guest[]) => {
          if (this.module === 'trash') {
            this.setupTrashGuestList(guestList);
          }
        }, error: (err: any) => {
          this.errorService.logError(err);
        }
      });
    }
  }

  // async unwatch() {
  //   await this.unwatchGuestList();
  //   // this.unwatchCheckinGuestList();
  //   await this.unwatchTrashGuestList();
  //   this.setupGuestList([]);
  // }

  /**
   * Unwatch Guest list
   */
  async unwatchGuestList() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Unwatch Guest list
   */
  async unwatchTrashGuestList() {
    if (this.trashGuestListSubscription) {
      this.trashGuestListSubscription.unsubscribe();
      this.trashGuestListSubscription = null;
    }
  }

  /**
   * Watch gift list
   */
  async watchGiftList() {
    if (this.accountId && !this.giftListSubscription) {
      this.giftListSubscription = this.giftService.observableGiftList.subscribe(() => {
        this.setupGiftGuestList();
      });
    }
  }

  /**
   * Unwatch gift list
   */
  async unwatchGiftList() {
    if (this.giftListSubscription) {
      this.giftListSubscription.unsubscribe();
      this.giftListSubscription = null;
    }
  }

  async setupGiftGuestList() {
    const giftList = this.giftService?.getGiftList();
    if (giftList?.length) {
      const guestList = this.getGuestList();
      if (guestList?.length) {
        this.setupGuestList(guestList);
      } else {
        await this.functionService.delay(1000);
        this.setupGiftGuestList();
      }
    } 
  }

  setupGuestSession(guest: Guest): Guest {
    if (!guest?.session?.length) {
      guest.session = [ {
        custom: false,
        value: 'none'
      }];
    }
    return guest;
  }

  setupGuestMobile(guest: Guest): Guest {
    if (guest?.mobile?.no) {
      guest.mobile.no = guest.mobile.no.replace(/ /g, '').replace(/\-/g, '');
    }
    return guest;
  }

  /**
   * Setup guest list
   * @param guestList guest list
   */
  setupGuestList(guestList: Guest[]) {
    if (this.module === 'gift' && this.giftService?.getGiftList()?.length) {
      guestList?.forEach((guest: Guest) => {
        guest.giftList = this.giftService.getGiftIdListByGuestId(guest?.guestId);
      });
    }

    this.guestList = guestList;
    this.observableGuestList.next(this.guestList);
    
    this.checkinGuestList = [ ...this.guestList ]?.filter((guest: Guest) => {
      return this.excludeGuestForCheckin(guest);
    });
    this.observableCheckinGuestList.next(this.checkinGuestList);
  }

  setupTrashGuestList(guestList: Guest[]) {
    this.trashGuestList = guestList;
    this.observableTrashGuestList.next(this.trashGuestList);
  }

  /**
   * Set check-in module setting for guest status
   * @param pendingGuest show pending guest
   * @param notAttendingGuest show not attending guest
   */
  setCheckinSetting(pendingGuest: boolean, notAttendingGuest: boolean) {
    this.pendingGuest = pendingGuest;
    this.notAttendingGuest = notAttendingGuest;
  }

  getGuestList(module?: ModuleType): Guest[] {
    if (!module) {
      module = this.module;
    }
    if (module === 'checkin') {
      if (this.checkinGuestList?.length) {
        return [ ...this.checkinGuestList ];
      }
    } else if (module === 'trash') {
      return this.trashGuestList ? [ ...this.trashGuestList ] : [];
    } else if (module === 'guest' || module === 'gift' || module === 'blast' || module === 'account' || module === 'rsvp') {
      return this.guestList ? [ ...this.guestList ] : [];
    }
    return [];
  }

  /**
   * Get Guest
   * @param guestId Guest ID
   * @returns guest
   */
  getGuest(guestId: string, module?: ModuleType): Guest {
    let guest: Guest;
    const guestList = this.getGuestList(module);
    const index: number = guestId ? guestList?.findIndex((x: Guest) => x?.guestId === guestId) : -1;
    if (index !== -1) {
      guest = guestList[index];
    }
    return guest;
  }

  /**
   * Get guest list by guest id list
   * @param guestIdList guest id list
   * @returns guest list
   */
  getGuestListById(guestIdList: string[], module?: ModuleType): Guest[] {
    const guestList = this.getGuestList(module);
    return guestList?.length ? guestList.filter((guest: Guest) => {
      if (guest?.guestId && guestIdList?.indexOf(guest.guestId) !== -1) {
        return true;
      } else {
        return false;
      }
    }).sort((a: Guest, b: Guest) =>
      this.functionService.compare(a.name?.toString().toLowerCase(), b.name?.toString().toLowerCase()
    )) : [];
  }

  /**
   * Get guest by code
   * @param code guest code
   * @returns guest
   */
  getGuestByCode(code: string, module?: ModuleType): Guest {
    let guest: Guest;
    const guestList = this.getGuestList(module);
    const index: number = code ? guestList.findIndex((x: Guest) => x?.code === code) : -1;
    if (index !== -1) {
      guest = guestList[index];
    }
    return guest;
  }

  getAccountId() {
    return this.accountId;
  }
  
  /**
   * Check guest qrcode.
   * First check guest id then guest code.
   * @param qrcode qrcode
   */
  checkGuestQrcode(qrcode: string, module?: ModuleType): Guest {
    const guest: Guest = this.getGuest(qrcode, module);
    if (guest) {
      return guest;
    } else {
      return this.getGuestByCode(qrcode);
    }
  }

  /**
   * Get guest url
   * @param guestId Guest ID
   * @returns URL string
   */
  getGuestUrl(guestId: string): string {
    return DynamicUrl.long.visitor + this.accountId + '/' + guestId;
  }

  /**
   * Get guest checkin status.
   * @param guestId guest id
   * @returns true, if guest attended, else not_attend.
   */
  getGuestCheckinStatus(guestId: string, module?: ModuleType): boolean {
    if (guestId) {
      const guest = this.getGuest(guestId, module);
      if (guest?.status?.checkin) { return true; }
    }
    return false;
  }

  /**
   * Get guest by seating
   * @param seatingName Seating name
   * @returns List of guest from the seating
   */
  getSeatingGuest(seatingName: string, module?: ModuleType): Guest[] {
    const guestList = this.getGuestList(module);
    if (guestList?.length) {
      return guestList.filter((guest: Guest) => {
        if (guest?.seating === seatingName) {
          return true;
        } else {
          return false;
        }
      });
    }
    return null;
  }

  /**
   * Get guest list by seating
   * @param seatingList seating list
   * @returns guest list
   */
  getSeatingListGuest(seatingList: Seating[]): Guest[] {
    let guestList: Guest[] = [];
    seatingList?.forEach((seating: Seating) => {
      const list = this.getSeatingGuest(seating?.name);
      if (list?.length) {
        guestList = [ ...new Set([ ...guestList, ...list] ) ];
      }
    });
    return guestList;
  }

  /**
   * Get guest seating
   * @param guestId guest id
   */
  getGuestSeating(guestId: string, module?: ModuleType): string {
    const guest = this.getGuest(guestId, module);
    return guest?.seating ? guest.seating : '';
  }

  getGuestSession(guestId: string, module?: ModuleType): SettingField[] {
    const guest = this.getGuest(guestId, module);
    return guest?.session ? guest.session : [];
  }

  /**
   * Get guest name
   * @param guestId guest id
   * @returns Guest name
   */
  getGuestName(guestId: string, module?: ModuleType): string {
    const guest = this.getGuest(guestId, module);
    return guest?.name ? guest.name : '';
  }

  /**
   * Get group seating
   * @param membeList list of group member
   * @returns seating list of the group member without duplicate
   */
  getGroupSeating(memberList: string[]): string[] {
    let seatingList: string[] = [];
    memberList?.forEach((guestId: string) => {
      const seating = this.getGuestSeating(guestId);
      if (seating && seatingList?.indexOf(seating) === -1) {
        seatingList.push(seating);
      }
    });
    if (seatingList?.length) {
      seatingList = seatingList.sort((a: string, b: string) => {
        return this.functionService.compare(a?.toString().toLowerCase(), b?.toString().toLowerCase());
      });
    }
    return seatingList;
  }

  getGroupSession(memberList: string[]): SettingField[] {
    let sessionList: SettingField[] = [];
    memberList?.forEach((guestId: string) => {
      const guesetSession = this.getGuestSession(guestId);
      guesetSession?.forEach((session: SettingField) => {
        if (session?.value !== 'none' && !session.custom) {
          const index = sessionList.findIndex((x: SettingField) => {
            if (x.value === session.value && x.custom === session.custom) {
              return true;
            }
            return false;
          });
          if (index === -1) {
            sessionList.push(session);
          }
        }
      });
    });
    if (sessionList?.length) {
      sessionList = sessionList.sort((a: SettingField, b: SettingField) => {
        return this.functionService.compare(a.value?.toString().toLowerCase(), b.value?.toString().toLowerCase());
      });
    }
    return sessionList;
  }

  getGroupListMember(groupIdList: string[], module?: ModuleType): Guest[] {
    let guestList: Guest[] = [];
    if (groupIdList?.length) {
      groupIdList?.forEach((groupId: string) => {
        const memberList = this.getGroupMember(groupId, module);
        if (memberList?.length) {
          guestList = guestList.concat(memberList);
        }
      });
    }
    return guestList;
  }

  /**
   * Get group member list
   * @param groupId Group id
   * @returns guest list
   */
  getGroupMember(groupId: string, module?: ModuleType): Guest[] {
    const guestList = this.getGuestList(module);
    return groupId && guestList?.length ? guestList.filter((guest: Guest) => {
      if (this.module === 'trash') {
        return guest?.status?.deleted && guest.groupId === groupId ? true : false;
      } else {
        return !guest?.status?.deleted && guest.groupId === groupId ? true : false;
      }
    }) : [];
  }

  /**
   * Get group member id list
   * @param groupId group id
   * @returns group member id list
   */
  getGroupMemberId(groupId: string): string[] {
    return this.getGroupMember(groupId).map((guest: Guest) => {
      return guest.guestId;
    });
  }

  /**
   * Get group member count
   * @param groupId group id
   * @returns count of group member
   */
  getGroupMemberCount(groupId: string): number {
    return this.getGroupMember(groupId).length;
  }

  /**
   * Get guest group id
   * @param guestId guest id
   * @returns guest group id if applicable
   */
  getGuestGroupId(guestId: string, module?: ModuleType): string {
    const guest = this.getGuest(guestId, module);
    return guest?.groupId;
  }

  excludeGuestForCheckin(guest: Guest): boolean {
    if (!this.notAttendingGuest && guest?.status?.attending === 'not_attending') {
      return false;
    }
    if (!this.pendingGuest && guest?.status?.attending === 'pending') {
      return false;
    }
    return true;
  }
}
