import { FunctionService } from 'src/app/services/general/function.service';
import { LinkService } from 'src/app/services/general/link.service';
import { DeviceService } from 'src/app/services/device/device.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UrlService } from 'src/app/services/general/url.service';
import { NavigationExtras, Router } from '@angular/router';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import { Platform } from '@ionic/angular';

import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { AppDownloadUrl, DynamicUrl } from 'src/app/commons/url';

const APP_VERSION = '0.12.17';
const APP_BUILD = '1216';
@Injectable({
  providedIn: 'root'
})
export class AppService implements OnInit, OnDestroy {

  /**
   * App info
   */
  appInfo: AppInfo;

  appVersion: string;

  appBuild: string;

  updatePage: boolean;

  newVersion = '';
  minVersion = '';

  forceUpdate: boolean;

  playStore: boolean;
  appGallery: boolean;

  private urlSubscription: Subscription;

  observableNewVersion: any;
  observableMinVersion: any;

  constructor(
    private router: Router,
    private platform: Platform,
    private deviceService: DeviceService,
    private appAvailability: AppAvailability,
    private urlService: UrlService,
    private linkService: LinkService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.observableNewVersion = new BehaviorSubject<string>(this.newVersion);
    this.observableMinVersion = new BehaviorSubject<string>(this.minVersion);
    this.getInfo();
    this.checkAndroidStore();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.unwatchUrl();
  }

  async checkAndroidStore() {
    await this.platform.ready();
    if (this.platform.is('hybrid') && this.platform.is('android')) {
      this.playStore = await this.checkPlayStore();
      this.appGallery = await this.checkAppGallery();
      this.linkService.playStore = this.playStore;
      this.linkService.appGallery = this.appGallery;
    }
  }

  /**
   * Watch URL
   */
  async watchUrl() {
    if (!this.urlSubscription) {
      this.urlSubscription = this.urlService.observableUrl.subscribe(() => {
        if (this.minVersion && this.forceUpdate) {
          this.checkMinVersion(this.minVersion);
        }
      });
    }
  }

  /**
   * Unwatch URL
   */
  async unwatchUrl() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
      this.urlSubscription = null;
    }
  }

  /**
   * Get device info
   */
  async getInfo(): Promise<AppInfo> {
    await this.platform.ready();
    if (this.platform.is('hybrid')) {
      if (!this.appInfo) {
        this.appInfo = await App.getInfo();
      }
      if (this.appInfo?.version) {
        this.appVersion = this.appInfo.version;
      }
      if (this.appInfo?.build) {
        this.appBuild = this.appInfo.build;
      }

      if (!this.appVersion) {
        this.appVersion = APP_VERSION;
      }

      if (!this.appBuild) {
        this.appBuild = APP_BUILD;
      }
    } else {
      this.appVersion = APP_VERSION;
      this.appBuild = APP_BUILD;
    }
    this.errorService.appVersion = this.appVersion;
    return this.appInfo;
  }

  /**
   * Get App version
   * @returns App version
   */
  async getAppVersion() {
    if (!this.appInfo) {
      await this.getInfo();
    }
    return this.appVersion ? this.appVersion : APP_VERSION;
  }

  async getAppBuild() {
    if (!this.appInfo) {
      await this.getInfo();
    }
    return this.appBuild ? this.appBuild : APP_BUILD;
  }

  async checkPlayStore() {
    if (this.platform.is('hybrid') && this.platform.is('android') && await this.appAvailability.check('com.android.vending')) {
      return true;
    }
    return false;
  }

  async checkAppGallery() {
    if (this.platform.is('hybrid') && this.platform.is('android') && await this.appAvailability.check('com.huawei.appmarket')) {
      return true;
    }
    return false;
  }

  async checkNewVersion(newVersion: string) {
    await this.platform.ready();
    if (newVersion) {
      this.newVersion = newVersion.toString();
      this.observableNewVersion.next(this.newVersion);
      await this.getAppVersion();
      if (this.functionService.compareVersion(this.newVersion, this.appVersion, '>')) {
        this.goAppUpdatePage(newVersion);
      }
    }
  }

  async checkMinVersion(minVersion: string) {
    await this.platform.ready();
    if (minVersion) {
      this.minVersion = minVersion.toString();
      this.observableMinVersion.next(this.minVersion);
      await this.getAppVersion();

      if (this.functionService.compareVersion(this.minVersion, this.appVersion, '>')) {
        this.forceUpdate = true;
        this.goAppUpdatePage(minVersion, true);
      } else {
        this.forceUpdate = false;
      }
    }
  }

  goAppUpdatePage(newVersion: string, forceUpdate?: boolean) {
    const appUpdatePage = '/about/app-update';
    if (newVersion && !this.urlService.checkUrl([ appUpdatePage ]) && !this.urlService.checkUrl(['/vip']) && !this.urlService.checkVipHostName()) {
      const navigationExtras: NavigationExtras = {
        state: { newVersion, forceUpdate }
      };
      this.router.navigate([ appUpdatePage ], navigationExtras);
    }
  }

  /**
   * Go update
   */
  async goStore() {
    await this.platform.ready();
    const device = this.deviceService.deviceInfo;
    
    if (this.platform.is('ios') || device?.operatingSystem === 'mac') {
      this.openAppStore();
    } else if (this.platform.is('android')) {
      const userAgent = window.navigator.userAgent.toString();
      const huaweiIndex = userAgent?.toLowerCase().indexOf('huawei');
      if ((!this.platform.is('hybrid') && (huaweiIndex === -1) || userAgent?.toLowerCase().indexOf('gms') !== -1)
      || this.playStore) {
        this.openPlayStore();
      } else if ((!this.platform.is('hybrid') && (huaweiIndex !== -1) || userAgent?.toLowerCase().indexOf('hms') !== -1)
      || this.appGallery) {
        this.openAppGallery();
      } else {
        this.openPlayStore();
      }
    } else if (!this.urlService.checkUrl([ AppDownloadUrl ])) {
      this.linkService.goAppDownloadPage(true);
    }
  }

  async goRating() {
    await this.platform.ready();
    const device = this.deviceService.deviceInfo;
    if (this.platform.is('hybrid') && this.platform.is('ios')) {
      this.openAppStore();
    } else if (this.platform.is('hybrid') && this.platform.is('android')) {
      const userAgent = window.navigator.userAgent;
      const huaweiIndex = userAgent?.toLowerCase().indexOf('huawei');
      if ((!this.platform.is('hybrid') && (huaweiIndex === -1) || userAgent?.toLowerCase().indexOf('gms') !== -1)
      || this.playStore) {
        this.openPlayStore();
      } else if ((!this.platform.is('hybrid') && (huaweiIndex !== -1) || userAgent?.toLowerCase().indexOf('hms') !== -1)
      || this.appGallery) {
        this.openAppGallery();
      } else {
        this.openPlayStore();
      }
    } else {
      this.openFacebookReview();
    }
  }

  openAppStore() {
    this.openUrl(DynamicUrl.long.store.appstore, true);
  }

  openPlayStore() {
    this.openUrl(DynamicUrl.long.store.playstore, true);
  }

  openAppGallery() {
    this.openUrl(DynamicUrl.long.store.appgallery, true);
  }

  openFacebookReview() {
    this.openUrl(DynamicUrl.long.review.facebook);
  }

  openGoogleProfile() {
    this.openUrl(DynamicUrl.long.review.google);
  }

  openWebVersion() {
    this.openUrl(DynamicUrl.long.home);
  }

  async openUrl(url: string, skipIAP?: boolean) {
    this.linkService.openUrl(url, skipIAP);
  }

  checkIap() {
    if (this.platform.is('hybrid')) {
      if (this.platform.is('ios')) {
        return true;
      } else if (this.platform.is('android')) {
        if (this.playStore) {
          return true;
        }
      }
    }
    return false;
  }

}
